<template>
    <van-dropdown-menu>
        <van-dropdown-item :title="title" ref="language">
            <van-cell v-for="lau in languageOption" :key="lau.value" @click="setLanguage(lau)">
                <template>
                    <div style="display:flex;align-items: center;">
                        <!-- <van-image v-if="lau.image" width="20px" height="20px" fit="cover" :src="lau.image" /> -->
                        <div :style="{ 'margin-left': '10px', 'color': language == lau.value ? '#ee0a24' : '#323233' }">
                            {{ lau.text }}
                        </div>
                    </div>
                </template>
            </van-cell>
        </van-dropdown-item>
    </van-dropdown-menu>
</template>
  
<script>
const Config = {
    'DEFAULT': {
        option: [
            { text: 'English', value: 'en' }
        ]
    },
    'KR': {
        option: [
            { text: '한국어', value: 'kr'},
            { text: 'English', value: 'en'},
        ]
    },
    'JPN': {
        option: [
            { text: 'Japanese', value: 'jpn'},
            { text: 'English', value: 'en'},
        ]
    },
}

import {
    DropdownMenu,
    DropdownItem,
    Image as VanImage,
} from 'vant';

export default {
    components: {
        [VanImage.name]: VanImage,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
    },
    data() {
        return {
            language: '',
            country: '',
            title: '',
            languageOption: [],
        };
    },
    created() {
        this.language = this.$route.params.curLang || this.$route.params.lang || 'en'
        this.country = this.$route.params.countryPath
        this.languageOption = Config[this.country] ? Config[this.country].option : Config['DEFAULT'].option
        this.$nextTick(() => {
            this.setLanguage(this.languageOption.find(i => i.value == this.language) || this.languageOption[0], true)
        });
    },
    methods: {
        setLanguage(language, not_toggle) {
            this.language = language.value
            this.title = language.text
            this.$root.$i18n.locale = language.value
            if (!not_toggle) {
                this.$refs.language.toggle()
            }
        },
    },
    //监听this.$root.$i18n.locale变化，实时更新语言
    watch: {
        "$root.$i18n.locale": function (newVal) {
            console.log(`lang changed to ${newVal}`)
            this.setLanguage(this.languageOption.find(i => i.value == newVal) || this.languageOption[0], true)
        }
    }
};
</script>
  
<style lang="less"></style>
  